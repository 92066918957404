import moment from "moment";

export const changeMomentLanguage = (langKey) => {
    switch (langKey) {
        case 'ru':
            moment.locale('ru', {
                months: 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split('_'),
                monthsShort: 'янв._фев._мар._апр._мая_июн._июл._авг._сен._окт._ноя._дек.'.split('_'),
                weekdays: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
                weekdaysShort: 'вс._пн._вт._ср._чт._пт._сб.'.split('_'),
                weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
                longDateFormat: {
                    LT: 'HH:mm',
                    LTS: 'HH:mm:ss',
                    L: 'DD.MM.YYYY',
                    LL: 'D MMMM YYYY г.',
                    LLL: 'D MMMM YYYY г., HH:mm',
                    LLLL: 'dddd, D MMMM YYYY г., HH:mm',
                },
                calendar: {
                    sameDay: '[Сегодня в] LT',
                    nextDay: '[Завтра в] LT',
                    lastDay: '[Вчера в] LT',
                    nextWeek: function (now) {
                        if (now.week() !== this.week()) {
                            switch (this.day()) {
                                case 0:
                                    return '[В следующее] dddd [в] LT';
                                case 1:
                                case 2:
                                case 4:
                                    return '[В следующий] dddd [в] LT';
                                case 3:
                                case 5:
                                case 6:
                                    return '[В следующую] dddd [в] LT';
                            }
                        } else {
                            if (this.day() === 2) {
                                return '[Во] dddd [в] LT';
                            } else {
                                return '[В] dddd [в] LT';
                            }
                        }
                    },
                    lastWeek: function (now) {
                        if (now.week() !== this.week()) {
                            switch (this.day()) {
                                case 0:
                                    return '[В прошлое] dddd [в] LT';
                                case 1:
                                case 2:
                                case 4:
                                    return '[В прошлый] dddd [в] LT';
                                case 3:
                                case 5:
                                case 6:
                                    return '[В прошлую] dddd [в] LT';
                            }
                        } else {
                            if (this.day() === 2) {
                                return '[Во] dddd [в] LT';
                            } else {
                                return '[В] dddd [в] LT';
                            }
                        }
                    },
                    sameElse: 'L',
                },
                relativeTime: {
                    future: 'через %s',
                    past: '%s назад',
                    s: 'несколько секунд',
                    m: 'минуту',
                    mm: '%d минут',
                    h: 'час',
                    hh: '%d часов',
                    d: 'день',
                    dd: '%d дней',
                    M: 'месяц',
                    MM: '%d месяцев',
                    y: 'год',
                    yy: '%d лет',
                },
                meridiemParse: /ночи|утра|дня|вечера/,
                isPM: function (input) {
                    return ['ночи', 'вечера'].includes(input);
                },
                meridiem: function (hour) {
                    if (hour < 4) {
                        return 'ночи';
                    } else if (hour < 12) {
                        return 'утра';
                    } else if (hour < 17) {
                        return 'дня';
                    } else {
                        return 'вечера';
                    }
                },
                dayOfMonthOrdinalParse: /\d{1,2}-(й|го)/,
                ordinal: function (number, period) {
                    if (period === 'M' || period === 'd' || period === 'DDD' || period === 'w' || period === 'W') {
                        return number + '-й';
                    } else if (period === 'D') {
                        return number + '-го';
                    } else {
                        return number;
                    }
                },
                week: {
                    dow: 1, // Monday is the first day of the week.
                    doy: 4, // Used to determine the first week of the year.
                },
            });

            break;
        case 'am':
            moment.locale('hy', {
                months: 'հունվար_փետրվար_մարտ_ապրիլ_մայիս_հունիս_հուլիս_օգոստոս_սեպտեմբեր_հոկտեմբեր_նոյեմբեր_դեկտեմբեր'.split('_'),
                monthsShort: 'հնվ_փտր_մրտ_ապր_մյս_հնս_հլս_օգս_սեպ_հոկ_նոյ_դեկ'.split('_'),
                weekdays: 'կիրակի_երկուշաբթի_երեքշաբթի_չորեքշաբթի_հինգշաբթի_ուրբաթ_շաբաթ'.split('_'),
                weekdaysShort: 'կրկ_երկ_երք_չրք_հնգ_ուրբ_շբթ'.split('_'),
                weekdaysMin: 'Կիր_Երկ_Երք_Չրք_Հնգ_Ուր_Շբթ'.split('_'),
                longDateFormat: {
                    LT: 'HH:mm',
                    LTS: 'HH:mm:ss',
                    L: 'DD/MM/YYYY',
                    LL: 'D MMMM YYYY',
                    LLL: 'D MMMM YYYY HH:mm',
                    LLLL: 'dddd D MMMM YYYY HH:mm',
                },
                calendar: {
                    sameDay: '[Այսօր] LT',
                    nextDay: '[Վաղը] LT',
                    lastDay: '[Երեկ] LT',
                    nextWeek: 'dddd [ժամը] LT',
                    lastWeek: '[Անցած] dddd [ժամը] LT',
                    sameElse: 'L',
                },
                relativeTime: {
                    future: '%sից',
                    past: '%s առաջ',
                    s: 'մի քանի վայրկյան',
                    ss: '%s վայրկյան',
                    m: 'մեկ րոպե',
                    mm: '%d րոպե',
                    h: 'մեկ ժամ',
                    hh: '%d ժամ',
                    d: 'մեկ օր',
                    dd: '%d օր',
                    M: 'մեկ ամիս',
                    MM: '%d ամիս',
                    y: 'մեկ տարի',
                    yy: '%d տարի',
                },
                dayOfMonthOrdinalParse: /\d{1,2}-(ին|րդ)/,
                ordinal: function (number, period) {
                    if (period === 'd') {
                        return number + '-ին';
                    } else if (period === 'w' || period === 'W') {
                        return number + '-րդ';
                    }
                    return number;
                },
                meridiemParse: /կեսից|կեսու/,
                isPM: function (input) {
                    return input === 'կեսու';
                },
                meridiem: function (hour) {
                    if (hour < 12) {
                        return 'կեսից';
                    }
                    return 'կեսու';
                },
                week: {
                    dow: 1, // Monday is the first day of the week.
                    doy: 4, // Used to determine the first week of the year.
                },
            });
            break;
        default:
            moment.locale('en')
            break;
    }
}